import { GC_USER_ID, GC_AUTH_TOKEN, GC_USER_ROLE, GC_PAYMENT } from "../constants/settings";
import { authByToken } from '../common/authByToken';
export default function auth({ to, next, router }) {
 
  authByToken(router, 'clinic-login').then( result => {
    if(result)
      return next();
    else
      if (!localStorage.getItem(GC_USER_ID) || !localStorage.getItem(GC_AUTH_TOKEN) ||  ['clinic_super_admin', 'clinic_admin', 'clinic_app'].indexOf(localStorage.getItem(GC_USER_ROLE)) == -1 || localStorage.getItem(GC_PAYMENT) != 'paid') {
        if(to.name == "clinic-withdraw")
          return router.push({ name: 'clinic-login', query: { withdraw:true }});
        else
          return router.push({ name: 'clinic-login' });
        }
    return next();
  })
}