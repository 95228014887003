import { apolloClient } from '../main.js';
import { ME_INFO_QUEY } from '../graphql/queries/me'
import { GC_USER_ID, GC_AUTH_TOKEN, GC_USER_ROLE, GC_IS_APP, GC_PAYMENT } from "../constants/settings";
export async function authByToken(router, name) {
    // localStorage.setItem(GC_IS_APP, false);
    var query_string = window.location.search.slice(1);
    var queries = query_string.split('?');
    let success = false;
    if(query_string != "")
        for(let query of queries) {
            var items = query.split('=');
            if(items[0] == "token") {
                localStorage.setItem(GC_AUTH_TOKEN, items[1]);
                localStorage.setItem(GC_IS_APP, true);
                let me = await apolloClient.query({ 
                    query: ME_INFO_QUEY,
                    fetchPolicy: 'no-cache',
                    headers: {
                        authorization: items[1]
                    }
                }).catch(err => {
                    console.log(err, name);
                    router.push({name: name});
                });
                
                if(me.data) {
                    console.log(me.data);
                    success = true;
                    localStorage.setItem(GC_USER_ID, me.data.me.id);
                    localStorage.setItem(GC_USER_ROLE, me.data.me.user_role);
                    localStorage.setItem(GC_PAYMENT, me.data.me.payment_status);
                }
            }
        }
    return success;
}
