import Vue from 'vue'
import Vuex from 'vuex'
import router from '../routes';
// import admin from './Admin';
// import cookie from 'vue-cookie'
import VueApollo from "vue-apollo";
import { ME_INFO_QUEY } from '../graphql/queries/me'
import { GC_AUTH_TOKEN, GC_LAST_LOGIN, GC_IS_APP } from '../constants/settings';
import * as helpers from '../common/helpers';
Vue.use(Vuex)
// Vue.use(cookie)
Vue.use(VueApollo);

const store = new Vuex.Store({
    state: {
        me: {},
        calendar_options: {},
        logout: false
    },
    getters: {
        getMe: (state) => async (payload) => {
            if(localStorage.getItem(GC_AUTH_TOKEN)) {

                if(!localStorage.getItem(GC_IS_APP) && localStorage.getItem(GC_LAST_LOGIN)) {
                    let now = new Date();
                    let expire_time = new Date(localStorage.getItem(GC_LAST_LOGIN));
                    
                    if(now.getTime() > expire_time) {
                        helpers.logout(router);
                    }
                }
               
                await payload.apolloClient.query({ 
                    query: ME_INFO_QUEY,
                    fetchPolicy: 'no-cache'
                })
                .then(data => {
                    state.me= data.data.me;
                    state.logout = false;
                })
                .catch((error) => {
                    if(typeof error.response == "undefined") {
                        if(!state.logout) {
                            alert("ご利用のアカウントは一時的に停止されたため、現在使用することができません。");
                            state.logout = true;
                        }
                        helpers.logout(router);
                    }
                })
            }
            return state.me;
        },
        
        getMeLocal(state) {
            return state.me;
        },
        
        getCalendarOptions(state) {
            return state.calendar_options;
        }
    },
    mutations: {
        SET_CALENDAR_OPTIONS(state, options) {
            state.calendar_options = options;
        },
    }
});

export default store;