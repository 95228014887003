import { GC_USER_ID, GC_AUTH_TOKEN, GC_USER_ROLE, GC_PAYMENT } from "../constants/settings";
import { authByToken } from '../common/authByToken';
export default function auth({ to, next, router }) {
  authByToken(router, 'labo-login').then( result => {
    if(result)
      return next();
    else
      if (!localStorage.getItem(GC_USER_ID) || !localStorage.getItem(GC_AUTH_TOKEN) || ['lab_super_admin', 'lab_admin', 'lab_app'].indexOf(localStorage.getItem(GC_USER_ROLE)) == -1 || localStorage.getItem(GC_PAYMENT) != 'paid') {
        if(to.name == "labo-withdraw")
          return router.push({ name: 'labo-login', query: { withdraw:true }});
        else
          return router.push({ name: 'labo-login' });
      }
    return next();
  })
}