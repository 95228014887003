import gql from "graphql-tag";

export const ME_INFO_QUEY = gql`
  query me {
    me {
      id
      clinic {
        id
        clinic_corp
        clinic_name
        clinic_name_f
        clinic_zip
        clinic_pref
        clinic_address
        clinic_phone
        delete_flg
        lab_password
      }
      lab {
        id
        parent_lab_id
        lab_name
        lab_phone
        lab_corp_kbn
        lab_address
        lab_pref
        lab_zip
      }
      lab_id
      child_lab_id
      clinic_id
      user_role
      user_type
      name_sei
      name_mei
      email
      qr_token
      reset_token
      token
      switch_role
      delete_flg
      payment_status
      employee_barcode
      child_lab {
        id
        parent_lab_id
        lab_name
        lab_phone
        lab_corp_kbn
        lab_address
        lab_pref
        lab_zip
      }
    }
  }
`;
