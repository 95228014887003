import { GC_AUTH_TOKEN} from "../constants/settings";
import { authByToken } from '../common/authByToken';
export default function auth({ next, router }) {
  authByToken(router, 'labo-login').then( result => {
    if(result)
      return next();
    else
      if ( !localStorage.getItem(GC_AUTH_TOKEN)) {
        return router.push({ name: 'labo-login' });
      }
    return next();
  })
}