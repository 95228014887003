import _ from "lodash";
import Joi from "joi";
import tlds from '/node_modules/@sideway/address/lib/tlds.js'
const schema = Joi.object({
    email: Joi.string()
        .email({ 
            minDomainSegments: 2,   
            tlds: {
                allow: tlds
            }
        })
});
export function validateEmail(mail) {
    let result = schema.validate({ email: mail});
    return typeof result.error == "undefined";
    //return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail)
}
export function minLenght(value, length) {
    return value.length < parseInt(length) && !_.isEmpty(value)
}
export function maxLenght(value, length) {
    return value.length > parseInt(length)
}
export function sameText(value, valuesame) {
    return value === valuesame
}
export function validatePhoneNumber(phone) {
    return /^\d.{0,20}$/.test(phone)
}

/*
@input: errorsFromCatch: Array
@output: JSON
*/
export function  graphQLErrorMessages(errorsFromCatch) {
    const errors = errorsFromCatch.graphQLErrors[0]
    return errors;
}

export function showCurrentDate()
{
  var dateObj = new Date();
  var month = dateObj.getUTCMonth() + 1; //months from 1-12
  var day = dateObj.getUTCDate();
  var year = dateObj.getUTCFullYear();
  var hour = dateObj.getHours();
  var minute = dateObj.getMinutes();

  return {year, month, day, hour, minute};
}

export function logout(router) {
    if(router.history._startLocation.indexOf('/clinic') != -1)
        router.push({ name: "clinic-login" });
    else 
        router.push({ name: "labo-login" });
}